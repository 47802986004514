import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate';
import modules from "./modules";

Vue.use(Vuex)

const dataState = createPersistedState();

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    modules,
    plugins: [dataState]
});
