<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <v-card elevation="3">
          <v-card-title>
            ApiKey
          </v-card-title>
          <v-card-text>
            <v-text-field
                dense
                label="apiKey"
                outlined
                :value="apiKeyValue"
                @input="inputApiKey"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="apiKeyValue !== 'ZWVdWhNugACQ6n62f3623kQDYrAuXJAt'"
        cols="12"
        sm="6"
      >
        <import-lead :class="{ disabled: isDisabled }"/>
      </v-col>
    </v-row>

    <v-row v-if="apiKeyValue !== 'ZWVdWhNugACQ6n62f3623kQDYrAuXJAt'">
      <v-col
        cols="12"
        sm="6"
      >
        <export-lead :class="{ disabled: isNotQuiz }"/>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <export-quiz :class="{ disabled: isNotQuiz }"/>
      </v-col>
    </v-row>

    <v-row v-if="apiKeyValue !== 'ZWVdWhNugACQ6n62f3623kQDYrAuXJAt'">
      <v-col
        cols="12"
      >
        <bayer-block :class="{ disabled: isNotQuiz }"/>
      </v-col>
    </v-row>
    <v-row v-if="apiKeyValue === 'ZWVdWhNugACQ6n62f3623kQDYrAuXJAt'">
        <v-col
                cols="12"
        >
            <manager-leads/>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ImportLead from "@/components/ImportLead";
import ExportLead from "@/components/ExportLead";
import ExportQuiz from "@/components/ExportQuiz";
import BayerBlock from "@/components/BayerBlock";
import ManagerLeads from "@/components/ManagerLeads";

export default {
  name: "HomePage",

  components: {
    ImportLead,
    ExportLead,
    ExportQuiz,
    BayerBlock,
    ManagerLeads,
  },

  data: () => ({
    dates: [], //'2022-10-27', '2022-10-28'
    menu: false,
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Team name', value: 'teamName' },
      { text: 'Channel', value: 'channel' },
      { text: 'Manager', value: 'manager' },
    ],
    newBayer: {
      name: '',
      teamName: '',
      channel: '',
      manager: '',
    },
    bayer: [],
  }),

  computed: {
    apiKeyValue() {
      return this.$store.state.stable.apiKey
    },

    isDisabled() {
      return this.apiKeyValue !== 'zhgwVyGlg93BEy1tPHwIuqylg0fGRIbo'
    },

    isNotQuiz() {
      return this.apiKeyValue !== '08FKNrbj5bHjlVeZTT26gcD3Xy90eFuh' && this.apiKeyValue !== 'zhgwVyGlg93BEy1tPHwIuqylg0fGRIbo'
    }
  },

  methods: {
    async inputApiKey(event) {
      this.$store.commit('stable/setApiKey', { value: event })
    },
  },
}
</script>

<style scoped>
.disabled {
  pointer-events: none;
  opacity: .5;
}
</style>
