<template>
  <v-card class="" elevation="3" :disabled="this.load">
    <v-card-title>
      Import
    </v-card-title>
    <v-card-text>
      <div class="d-flex">
        <v-file-input
            label="CSV файл для импорта"
            outlined
            dense
            @change="handleFileUpload($event)"
            class="mr-6"
        />
        <v-btn
            depressed
            color="primary"
            @click="sendFile()"
        >
          Загрузить
        </v-btn>
      </div>

      <div class="">{{textResult}}</div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "ImportLead",
  data: () => ({
    textResult: '',
    load: false,
  }),
  methods: {
    async handleFileUpload(event) {
      this.file = event
    },

    async sendFile() {
      if (this.file) {
        this.load = true;
        let formData = new FormData();
        formData.append('file', this.file);

        const res = await axios.post(`https://api.forexlead.info/csv`, formData, {
          headers: {
            apiKey: this.apiKeyValue,
            'Content-Type': 'multipart/form-data'
          }
        })

        if (res.status === 201) {
          this.load = false;
          this.textResult = `Файл успешно загружен(${res.data.count} с ${res.data.total} записей отправлено в очередь обработки)`
        } else {
          this.textResult = `Произошла какая-то ошибка, повторите позже`
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
