<template>
  <v-card class="" elevation="3">
    <v-card-title>
      Export Site Form
    </v-card-title>
    <v-card-text>
      <div class="d-flex">
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                class="mr-6"
                v-model="dateRangeText"
                label="Диапазон дат"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="dates"
              range
              no-title
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(dates)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-btn
            depressed
            color="primary"
            @click="exportFile()"
        >
          Export
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ExportLead",
  data: () => ({
    dates: [], //'2022-10-27', '2022-10-28'
    menu: false,
  }),

  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    apiKeyValue() {
      return this.$store.state.stable.apiKey
    },

    isExport() {
        return this.apiKeyValue === '08FKNrbj5bHjlVeZTT26gcD3Xy90eFuh' || this.apiKeyValue === 'zhgwVyGlg93BEy1tPHwIuqylg0fGRIbo'
    }
  },

  methods: {
    async exportFile() {
      if (this.isExport && this.dates.length === 2) {
        location.href = `https://api.forexlead.info/csv?fromDate=${this.dates[0]}&toDate=${this.dates[1]}`;
      }
    },
  }
}
</script>

<style scoped>

</style>
