<template>
  <v-card class="" elevation="3">
    <v-card-title>
      Create Bayer
    </v-card-title>
    <v-card-text>
      <div class="d-flex" style="gap: 16px;">
        <v-text-field
            label="Name"
            placeholder="Name"
            v-model="newBayer.name"
            outlined
            dense
        />
        <v-text-field
            label="Team name"
            placeholder="Team name"
            v-model="newBayer.teamName"
            outlined
            dense
        />
        <v-text-field
            label="Channel"
            placeholder="Channel"
            v-model="newBayer.channel"
            outlined
            dense
        />
        <v-text-field
            label="Manager"
            placeholder="Manager"
            v-model="newBayer.manager"
            outlined
            dense
        />
        <v-text-field
            label="ChatId"
            placeholder="ChatId"
            v-model="newBayer.chatId"
            outlined
            dense
        />
        <v-btn
            v-if="bayerId === null"
            depressed
            color="primary"
            @click="createBayer()"
        >
          Create
        </v-btn>
        <v-btn
            v-else
            depressed
            color="primary"
            @click="updateBayer()"
        >
          Update
        </v-btn>
        <v-btn
            v-if="bayerId !== null"
            icon
            color="green"
            @click="reset()"
        >
          <v-icon>mdi-cached</v-icon>
        </v-btn>
      </div>
      <div>
        <v-data-table
            :headers="headers"
            :items="bayer"
            :items-per-page="10"
            class="elevation-1"
            @click:row="onRow"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

const ENDPOINT = 'https://api.forexlead.info/bot-quiz/bayer'
// const ENDPOINT = 'http://localhost:3000/bot-quiz/bayer'

export default {
  name: "BayerBlock",
  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Team name', value: 'teamName' },
      { text: 'Channel', value: 'channel' },
      { text: 'Manager', value: 'manager' },
      { text: 'ChatId', value: 'chatId' },
    ],
    bayerId: null,
    newBayer: {
      name: '',
      teamName: '',
      channel: '',
      manager: '',
      chatId: '',
    },
    bayer: [],
  }),

  computed: {
    apiKeyValue() {
      return this.$store.state.stable.apiKey
    },
  },

  methods: {
    async createBayer() {
      if (this.apiKeyValue === 'zhgwVyGlg93BEy1tPHwIuqylg0fGRIbo') {
        await axios.post(ENDPOINT, this.newBayer, {
          headers: {
            apiKey: this.apiKeyValue,
          }
        })
        await this.reset()
        await this.fetchBayer()
      }
    },

    async updateBayer() {
      if (this.apiKeyValue === 'zhgwVyGlg93BEy1tPHwIuqylg0fGRIbo') {
        await axios.put(`${ENDPOINT}/${this.bayerId}`, this.newBayer, {
          headers: {
            apiKey: this.apiKeyValue,
          }
        })
        await this.reset()
        await this.fetchBayer()
      }
    },

    async fetchBayer() {
      const { data } = await axios.get(ENDPOINT, {
        headers: {
          apiKey: this.apiKeyValue,
        }
      })

      this.bayer = data;
    },

    onRow({ id, name, teamName, channel, manager, chatId }) {
      if (this.apiKeyValue === 'zhgwVyGlg93BEy1tPHwIuqylg0fGRIbo') {
        this.bayerId = id
        this.newBayer = {
          name,
          teamName,
          channel,
          manager,
          chatId,
        }
      }
    },

    reset() {
      this.bayerId = null
      this.newBayer = {
        name: '',
        teamName: '',
        channel: '',
        manager: '',
        chatId: '',
      }
    }
  },

  mounted() {
    if (this.apiKeyValue === '08FKNrbj5bHjlVeZTT26gcD3Xy90eFuh' || this.apiKeyValue === 'zhgwVyGlg93BEy1tPHwIuqylg0fGRIbo') {
      this.fetchBayer()
    }
  }
}
</script>

<style scoped>

</style>
