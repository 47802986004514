<template>
    <v-card>
        <v-text-field
            label="Manager"
            placeholder="Manager"
            v-model="manager"
            outlined
            dense
            @change="fetch()"
        />
        <v-card-title>
            <v-select
                :value="status"
                v-model="status"
                full-width
                dense
                hide-details
                :items="options.concat([{
                    text: '--Все лиды--',
                    value: 'all_leads'
                }, {
                    text: '--Без статуса--',
                    value: 'not_status'
                }])"
                label="Status"
                @input="filterLeads"
            ></v-select>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                @input="filterLeads"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        class="mr-6"
                        v-model="dateRangeText"
                        label="Диапазон дат"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dates"
                    range
                    no-title
                    scrollable
                    @input="filterLeads"
                >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates)"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="10"
            class="elevation-1"
        >
            <template v-slot:[`item.status`]="{item}">
                <div>
                    <v-select
                        :value="item.status"
                        full-width
                        dense
                        hide-details
                        :items="options"
                        label="Status"
                        @input="setStatus($event, item.id)"
                    ></v-select>
                </div>
            </template>
            <template v-slot:[`item.totalClose`]="{item}">
                <v-text-field
                    label="Закрыли"
                    placeholder="Закрыли"
                    :rules="[numberRule]"
                    v-model="item.totalClose"
                    @blur="setTotalClose($event, item.id)"
                />
            </template>
            <template v-slot:[`item.totalMirror`]="{item}">
                <v-text-field
                    label="Зеркалка"
                    placeholder="Зеркалка"
                    :rules="[numberRule]"
                    v-model="item.totalMirror"
                    @blur="setTotalMirror($event, item.id)"
                />
            </template>
            <template v-slot:[`item.comment`]="{item}">
                    <v-text-field
                        label="Comment"
                        placeholder="Comment"
                        v-model="item.comment"
                        @blur="setComment($event, item.id)"
                    />
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import axios from "axios";

const ENDPOINT = 'https://api.forexlead.info/manager/leads/'
// const ENDPOINT = 'http://localhost:3000/manager/leads/'

export default {
    name: "ManagerLeads",
    data: () => ({
        headers: [
            {text: 'Chat ID', value: 'chatId'},
            {text: 'Username', value: 'data.username'},
            {text: 'First Name', value: 'data.first_name'},
            {text: 'Last Name', value: 'data.last_name'},
            {text: 'Phone', value: 'data.phone'},
            {text: 'Utm Source', value: 'query.query.utm_source'},
            {text: 'Status', value: 'status'},
            {text: 'Закрыли', value: 'totalClose'},
            {text: 'Зеркалка', value: 'totalMirror'},
            {text: 'Comment', value: 'comment'},
        ],
        items: [],
        manager: '', //@kVitalii42
        options: [{
            text: 'Написал в личку',
            value: 'message'
        }, {
            text: 'Закрыли',
            value: 'close'
        }, {
            text: 'Нет ответа',
            value: 'no_answer'
        }, {
          text: 'Срез',
          value: 'slice'
        }, {
          text: 'Удалил чат',
          value: 'delete_chat'
        }, {
          text: 'Заблокировал',
          value: 'block'
        }, {
          text: 'Бомж',
          value: 'bum'
        }],
        search: '',
        status: 'all_leads',
        dates: [],
        menu: false,
        numberRule: v  => {
            if (!isNaN(parseFloat(v))) return true;
            return 'Це поле має бути числом';
        },
    }),

    computed: {
        apiKeyValue() {
            return this.$store.state.stable.apiKey
        },
        dateRangeText () {
            return this.dates.join(' ~ ')
        },
    },

    methods: {
        async fetch() {
            if (this.manager !== '') {
                const {data} = await axios.get(ENDPOINT + this.manager, {
                    headers: {
                        apiKey: this.apiKeyValue,
                    }
                })

                this.items = data;
            }
        },
        async setStatus(event, id) {
            await axios.put(ENDPOINT + id + '/status', {
                status: event
            }, {
                headers: {
                    apiKey: this.apiKeyValue,
                }
            })
        },

        async setComment(event, id) {
            await axios.put(ENDPOINT + id + '/comment', {
                comment: event.target.value
            }, {
                headers: {
                    apiKey: this.apiKeyValue,
                }
            })
        },

        async setTotalClose(event, id) {
            await axios.put(ENDPOINT + id + '/total-close', {
                totalClose: event.target.value.replace(',', '.')
            }, {
                headers: {
                    apiKey: this.apiKeyValue,
                }
            })
        },

        async setTotalMirror(event, id) {
            await axios.put(ENDPOINT + id + '/total-mirror', {
                totalMirror: event.target.value
            }, {
                headers: {
                    apiKey: this.apiKeyValue,
                }
            })
        },

        async filterLeads() {
            let query = `?status=${this.status}&username=${this.search}`
            if (this.dates.length === 2) {
                query += `&fromDate=${this.dates[0]}&toDate=${this.dates[1]}`;
            }
            const {data} = await axios.get(`${ENDPOINT}${this.manager}${query}`, {
                headers: {
                    apiKey: this.apiKeyValue,
                }
            })

            this.items = data;
        }
    },
    mounted() {
        this.fetch();
    }
}
</script>

<style scoped>

</style>
